

































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Navbar from '@/views/template/Navbar.vue';
import Footer from '@/views/template/Footer.vue';

export default Vue.extend({
  name: 'Template',
  components: { Navbar, Footer },
  computed: {
    pageTitle() {
      return this.$route?.meta?.title;
    },
    ...mapGetters({ getUserData: 'getUserData' }),
  },
  created() {
    this.$store.commit('GET_USER_INFORMATION');
  },
});
