






















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Navbar',
  computed: {
    ...mapGetters({ getUserData: 'getUserData' }),
    sidebarItems() {
      return [
        {
          title: 'Dashboard',
          icon: 'fas fa-tachometer-alt',
          action: '/dashboard',
        },
        {
          title: 'Usuários',
          icon: 'fas fa-solid fa-user',
          action: '/users',
          role: 'admin',
        },
      ].filter(
        (item) => item.role === undefined || item.role === this.getUserData?.role,
      );
    },
  },
});
